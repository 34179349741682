import React from 'react';
// import Mailchimp from '../mailchimp/Mailchimp';
import NavigationIcon from '../navigation/NavigationIcon';
export const Footer = () => {
  return (
    <div className="w-full blur-panel">
    <p className={'font-primary text-center w-full text-lg font-xl text-giek-dark-violet pb-2 pt-8'}>Become a follower of Reality Cult</p>
    <li className={'mx-auto flex justify-center gap-3'}>
      <a href="https://giek-1.bandcamp.com" target="_blank"><NavigationIcon type={'bandcamp'}/></a>
      <a href="https://www.instagram.com/realitycult.3.33.333/" target="_blank"><NavigationIcon type={'instagram'}/></a>
      <a href="https://open.spotify.com/artist/2qMBswfUwJpBeTKapK1ztf?si=PWqthRYrSBWLbGTUKYL28w&nd=1" target="_blank"><NavigationIcon type={'spotify'}/></a>
      <a href="https://www.facebook.com/profile.php?id=100092570799154" target="_blank"><NavigationIcon type={'facebook'}/></a>
      <a href="https://www.linkedin.com/in/stephanie-smit-6a711237/" target="_blank"><NavigationIcon type={'linkedin'}/></a>
      <a href="https://soundcloud.com/giek_1" target="_blank"><NavigationIcon type={'soundcloud'}/></a>
      <a href="https://youtube.com/@giek1realitycult" target="_blank"><NavigationIcon type={'youtube'}/></a>
    </li>
    <p className={'font-primary text-center w-full text-giek-dark-violet text-sm font-bold pt-2 pb-8'}>Reality Cult ©</p>
    </div>
  )
}

export default Footer;
