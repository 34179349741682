import React, {useState, useEffect} from 'react'
import DoorSvg from './DoorSvg.js'
import ManifestoSvg from './ManifestoSvg.js';
import PinwheelSvg from './PinwheelSvg.js';
import {AngelCardSlider} from '../index'

export const AngelManifesto = ({manifesto}) => {
  return (
    <div className={'w-full overflow-x-hidden'}>
    <div className={'absolute w-full  min-h-[100vh] flex justify-center left-0 -z-[1] overflow-x-hidden -translate-y-[120px] xl:-translate-y-[160px]'}>
    <DoorSvg />
    </div>
    <div className={'w-full max-w-screen-2xl  mx-auto flex justify-center'}>


      <div className={'flex justify-center'}>
      <div className={'w-16 h-16 -translate-x-[482px] -translate-y-[40px] absolute border border-white rounded-xl rotate-45 blur-panel-light z-[999999]'}></div>
      <div className={'w-16 h-16 -translate-x-[482px] -translate-y-[20px] absolute border border-white rounded-xl rotate-45 blur-panel z-[999999]'}></div>
      <div className={'w-16 h-16 -translate-x-[482px] -translate-y-[0px] absolute border border-white rounded-xl rotate-45 blur-panel z-[999999]'}></div>
      <div className={'w-16 h-16 -translate-x-[482px] translate-y-[20px] absolute border border-white rounded-xl rotate-45 blur-panel z-[999999]'}></div>
      <div className={'w-16 h-16 -translate-x-[482px] translate-y-[40px] absolute border border-white rounded-xl rotate-45 blur-panel z-[999999]'}></div>
      <div className={'w-16 h-16 -translate-x-[482px] translate-y-[60px] absolute border border-white rounded-xl rotate-45 blur-panel-light z-[999999]'}></div>
      <div className={'w-16 h-16 -translate-x-[482px] translate-y-[80px] absolute border border-white rounded-xl rotate-45 blur-panel-light z-[999999]'}></div>
      <div className={'w-16 h-16 -translate-x-[482px] translate-y-[100px] absolute border border-white rounded-xl rotate-45  z-[999999]'}></div>

      <div className={'w-16 h-16 translate-x-[482px] -translate-y-[40px] absolute border border-white rounded-xl rotate-45 blur-panel-light z-[999999]'}></div>
      <div className={'w-16 h-16 translate-x-[482px] -translate-y-[20px] absolute border border-white rounded-xl rotate-45 blur-panel z-[999999]'}></div>
      <div className={'w-16 h-16 translate-x-[482px] -translate-y-[0px] absolute border border-white rounded-xl rotate-45 blur-panel z-[999999]'}></div>
      <div className={'w-16 h-16 translate-x-[482px] translate-y-[20px] absolute border border-white rounded-xl rotate-45 blur-panel z-[999999]'}></div>
      <div className={'w-16 h-16 translate-x-[482px] translate-y-[40px] absolute border border-white rounded-xl rotate-45 blur-panel z-[999999]'}></div>
      <div className={'w-16 h-16 translate-x-[482px] translate-y-[60px] absolute border border-white rounded-xl rotate-45 blur-panel-light z-[999999]'}></div>
      <div className={'w-16 h-16 translate-x-[482px] translate-y-[80px] absolute border border-white rounded-xl rotate-45 blur-panel-light z-[999999]'}></div>
      <div className={'w-16 h-16 translate-x-[482px] translate-y-[100px] absolute border border-white rounded-xl rotate-45  z-[999999]'}></div>
      </div>



    </div>
    <div className={'w-full max-w-screen-md mx-auto flex justify-center -translate-y-[120px]'}>
    <PinwheelSvg />
    </div>
          <div className={'w-full max-w-screen-md mx-auto flex justify-center'}>
          <ManifestoSvg />
          </div>


          <AngelCardSlider cards={manifesto} />

    </div>
  )
}

export default AngelManifesto