export const DoorSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"  className={'xl:w-[1446px] xl:h-[1603px]'} viewBox="0 0 1446 1603" fill="none">
    <g className={'blur-panel'}>
    <path d="M1447 780.626V2076H-1V780.626C-1 141.371 723 0 723 0C723 0 1447 141.371 1447 780.626Z" fill="white" fill-opacity="0.4"/>
    </g>
    <defs>
    <filter id="filter0_b_198_148" x="-21" y="-20" width="1488" height="2116" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feGaussianBlur in="BackgroundImageFix" stdDeviation="10"/>
    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_198_148"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_198_148" result="shape"/>
    </filter>
    </defs>
    </svg>
  )
}

export default DoorSvg
