import React, {useState, useEffect} from 'react'
import FrameSvg from '../angel-hero/FrameSvg.js';
import {Link} from 'react-router-dom';

export const AngelCardSlider = ({cards}) => {

  const [activeIndex, setActiveIndex]=useState(0)
  const [slideTransitionBackActive, setSlideTransitionBackActive] = useState(false)
    const [slideTransitionForwardActive, setSlideTransitionForwardActive] = useState(false)
    const [isSlidBackward, setIsSlidBackward] = useState(false);

  // const cards = [
  //   {
  //     title: '1',
  //     content: `<p>Since the dawn of time we have always been here. We’ve seen Pharaohs rise and legionaries fall. The truth has been buried or burned countless times, yet time and time again, we’ve been there to bring it back into the light.</p>
  //     <p>Humanity as a species faces internal turmoil that is mirrored onto their outer world. Opposing factions working towards radically different goals. The results of their actions causing apparently irreparable rifts in both the environment and collective psyche.</p>`
  //   },
  //   {
  //     title: '2',
  //     content: `<p>Since the dawn of time we have always been here. We’ve seen Pharaohs rise and legionaries fall. The truth has been buried or burned countless times, yet time and time again, we’ve been there to bring it back into the light.</p>
  //     <p>Humanity as a species faces internal turmoil that is mirrored onto their outer world. Opposing factions working towards radically different goals. The results of their actions causing apparently irreparable rifts in both the environment and collective psyche.</p>`
  //   },
  //   {
  //     title: '3',
  //     content: `<p>Since the dawn of time we have always been here. We’ve seen Pharaohs rise and legionaries fall. The truth has been buried or burned countless times, yet time and time again, we’ve been there to bring it back into the light.</p>
  //     <p>Humanity as a species faces internal turmoil that is mirrored onto their outer world. Opposing factions working towards radically different goals. The results of their actions causing apparently irreparable rifts in both the environment and collective psyche.</p>`
  //   },
  //   {
  //     title: '4',
  //     content: `<p>Since the dawn of time we have always been here. We’ve seen Pharaohs rise and legionaries fall. The truth has been buried or burned countless times, yet time and time again, we’ve been there to bring it back into the light.</p>
  //     <p>Humanity as a species faces internal turmoil that is mirrored onto their outer world. Opposing factions working towards radically different goals. The results of their actions causing apparently irreparable rifts in both the environment and collective psyche.</p>`
  //   },
  //   {
  //     title: 'Reality Cult',
  //     content: `<p>Since the dawn of time we have always been here. We’ve seen Pharaohs rise and legionaries fall. The truth has been buried or burned countless times, yet time and time again, we’ve been there to bring it back into the light.</p>
  //     <p>Humanity as a species faces internal turmoil that is mirrored onto their outer world. Opposing factions working towards radically different goals. The results of their actions causing apparently irreparable rifts in both the environment and collective psyche.</p>`
  //   }
  // ]

  const changeCard = (nextIndex) => {

    if(nextIndex > activeIndex) {
      setSlideTransitionForwardActive(true)
      setIsSlidBackward(false)
      setTimeout(function () {
        setActiveIndex(nextIndex)
      }, 500);
    } else {
      setIsSlidBackward(true)
      setSlideTransitionBackActive(true)
      setActiveIndex(nextIndex)
    }

  }

  useEffect(() => {
    setTimeout(function () {
      setSlideTransitionForwardActive(false)
      setSlideTransitionBackActive(false)
    }, 500);
  }, [slideTransitionForwardActive, slideTransitionBackActive])

  return (
    <>
    <div className={'block sm:grid max-w-screen-md mx-auto grid sm:grid-cols-2 mt-16 mb-16'}>
    { cards.map((card, i) => {
      return (
        <>


          <div className={'relative col-span-1 p-4'}>
            <div className={`bg-white w-full rounded-2xl p-8 min-h-[440px] shadow-xl ${slideTransitionForwardActive ? ' transition duration-500 -translate-x-[calc(100%+2rem)] ' : isSlidBackward ? '' : 'translate-x-0 fade-in'}`}>
              <h2 className={`font-heading mb-2  text-giek-purple text-6xl`}>{card.title}</h2>
              <p className={'font-primary'} dangerouslySetInnerHTML={{__html: card.content}}></p>

            </div>

          </div>


        </>
      )
    })
      }
    </div>
    </>
  )
}

export default AngelCardSlider;
