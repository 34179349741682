import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom';
import AngelTextColumn from '../components/angel-text-column/AngelTextColumn'
import {Slider} from '../components'
import PinwheelSvg from '../components/angel-manifesto/PinwheelSvg.js';
import { getPosts, getPage } from '../api';
import ReactGA from 'react-ga4';
const TRACKING_ID = "G-6RX2L9KSTD"; // OUR_TRACKING_ID

export const SpiritualGuidance = () => {

  const PAGE_ID = 471

  const [spiritualGuidanceContent, setSpiritualGuidanceContent] = useState(null)
  const [testimonials, setTestimonials] = useState(null);

  useEffect(() => {
    ReactGA.initialize([{ trackingId: TRACKING_ID }]);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Spiritual Guidance" });
  }, []);

  const getSpiritualGuidance = () => {
    getPage(PAGE_ID).then(result => {
      setSpiritualGuidanceContent([
        {
          title: result.acf.section_1.title,
          para_one: result.acf.section_1.top_paragraph,
          para_two: result.acf.section_1.bottom_paragraph,
          image: result.acf.section_1.image
        },
        {
          title: result.acf.section_2.title,
          para_one: result.acf.section_2.top_paragraph,
        },
        {
          title: result.acf.section_3.title,
          para_one: result.acf.section_3.top_paragraph,
          para_two: result.acf.section_3.bottom_paragraph,
          image: result.acf.section_3.image
        },
        {
          title: result.acf.section_4.title,
          para_one: result.acf.section_4.top_paragraph,
          para_two: result.acf.section_4.bottom_paragraph,
          image: result.acf.section_4.image
        },
        {
          title: result.acf.section_5.title,
          para_one: result.acf.section_5.top_paragraph,
          para_two: result.acf.section_5.bottom_paragraph,
          image: result.acf.section_5.image
        },
        {
          para_one: result.acf.section_6.top_paragraph,
          embed: result.acf.section_6.embed,
        }
      ])
      console.log(result)
    })
  }

  const CATEGORY_ID = 13
  const TESTIMONIAL_ID = 24

  const TESTIMONIALDATA = [
    {
      testimonial: 'I really enjoyed my astrology reading with Giek. We dove deep into the gritty details of my chart. Giek is a knowledgeable and incredibly intuitive astrologer. Her readings are insightful, comprehensive and accurate. We went through details of my personality and life path that really hit to the core of what I need to put In action to be in alignment with my higher self and life purpose',
      customer: 'Christine',
      fontSize: '0.875rem'
    },
    {
      testimonial: 'The reading has given me a new perspective on events from the past and present. I have also gained more confidence in my intuition because of things you have said.',
      customer: 'Aran Arunakiri',
      fontSize: '1.125rem'
    },
    {
      testimonial: 'I had an astrology and life purpose reading with Stephanie, because I had some important (business) decisions to make that involved a huge investment. I am so glad I had the chance to get this reading with Stephanie, because it reassured me in my decision making process and made clear what my path is. And I am walking it now ! Thank you so much Stephanie 💛',
      customer: 'Nancy',
      fontSize: '1.125rem'
    },
    {
      testimonial: 'After a period in which I lost my life path for a while, I was looking for clarity and a reminder of my right to exist.  An astrological reading provided me with the insights I needed to get back on the right path.  The reading gave me a deeper understanding of my personality and life purpose, and helped me redefine my priorities.',
      customer: 'Seger',
      fontSize: '1.125rem'
    },
    {
      testimonial: 'The astro reading with Giek was a great experience which led me to more understanding of myself and new insights in my being. She was very clear during the whole reading and very friendly and professional. I would definitely recommend it and would even give it as a gift to my friends and family',
      customer: 'Alana',
      fontSize: '1.125rem'
    },
    {
      testimonial: 'The astrological reading was eye opening. I would recommend everyone to have done it once in their life. Stephanie explained well and clearly how all the planets are related to your life phases and how it is all connected to the life path you are walking. It became clear to me where I have to go in the near future to fully stand in my strength. It also gave me confirmation that I am on the right path and everything happens for a reason.',
      customer: 'Emily',
      fontSize: '1.25rem'
    },
    {
      testimonial: "I had the privilege of working with Giek, and if you're looking to dive deeper into yourself and uncover hidden truths, I highly recommend her.  Giek provided a beautiful, safe setting where she guided me through my past lives and worked on my astral chart. Her insight was eye-opening—she revealed that I have blockages from past lives that are preventing me from fully experiencing a spiritual journey.",
      customer: 'Wesley',
      fontSize: '1.125rem'
    },
    {
      testimonial: "What stood out was Giek’s ability to weave the cards into a meaningful narrative for my path, diving into deeper symbolism and guiding me to reflect on overlooked areas of my life. Her advice was empowering and grounded, offering direction without being prescriptive.",
      customer: "Sara",
      fontSize: '1.125rem'
    }
  ]

    const [posts, setPosts] = useState(null)

    const getSpiritualServices = (page) => {
      getPosts(page, CATEGORY_ID).then(result => {
        setPosts(result.data.reverse().map(post => ({
          id: post.id,
          title: post.title.rendered,
         
          subtitle: post.acf.post_subtitle,
          slug: post.slug,
        })))
      })
    }

    const getTestimonials = () => {
      getPosts(1, TESTIMONIAL_ID).then(result => {
        setTestimonials(result.data.map(post => ({
          customer: post.title.rendered,
          testimonial: post.content.rendered,     
        })))
      })
    }

    useEffect(() => {
      getSpiritualGuidance()
      getSpiritualServices(1)
      getTestimonials();
      window.scrollTo(0, 0);
    }, [])

  useEffect(() => {
    window.scrollTo({
    top:0,
    behavior: 'smooth',
  });
  }, [])

  return (
    <div className={'min-h-[100vh]'}>
      <div className={' flex justify-center w-full mt-12'}>
        <h2 className={'fade-inglowing-purple-text text-giek-purple max-w-screen-sm font-heading text-4xl sm:text-6xl mt-8 w-full text-giek-grey pb-2 text-center uppercase'}>Spiritual Guidance</h2>
      </div>

      <div className={'-translate-y-12'}>
      {spiritualGuidanceContent && <AngelTextColumn pages={spiritualGuidanceContent.slice(0,1)} />}
      </div>
      {spiritualGuidanceContent && <div className={'py-32 sm:py-16 mb-16 sm:mb-32 font-bold'}>
      <Slider testimonials={testimonials} heading={'Testimonials'} />
      </div>}
{ spiritualGuidanceContent &&
  <>
      <div className={'flex justify-center w-full mt-8'}>
        <h2 className={'font-heading text-center font-bold mx-auto text-6xl glowing-purple-text text-giek-dark-violet mb-6'}>{spiritualGuidanceContent[1].title}</h2>
      </div>
      <div className={'flex max-w-screen-lg mx-auto justify-center w-full my-4 mb-8'}>
        <p className={'font-secondary text-lg text-center font-bold'} dangerouslySetInnerHTML={{__html: spiritualGuidanceContent[1].para_one }}></p>
      </div>


</>
      }
      <div className={'max-w-screen-lg  mx-auto grid sm:grid-cols-4 w-full mt-4 flex justify-center'}>
      {
        posts && posts.slice(0,4).map((service) => {
          return (
            <div className={'px-20 my-8 sm:my-0 sm:px-4 sm:p-4'}>
              <div className={'w-full rounded-xl mx-auto sm:min-h-[360px] bg-gradient-to-r from-cta-grad-to to-cta-grad-from shadow-md flex items-center '}>
                <Link to={`/spiritual-guidance/${service.slug}`}>
                <div className={'w-full'}>
                <div className={'h-[180px] flex justify-center items-end pb-4'}>
                <h4 className={'font-heading text-center text-3xl text-outlined'}>{service.title}</h4>
                </div>
                <div className={'h-[180px] px-8'}>
                <p className={'font-primary italic px-0 text-center text-xl text-outlined-white'}>{service.subtitle}</p>
                </div>
                </div>
                </Link>
              </div>
            </div>
          )
        })
      }
      </div>



      <div className={'max-w-screen-lg  mx-auto grid sm:grid-cols-8 w-full mt-4 flex justify-center'}>

        <div className={'col-span-1'}>
        </div>
        {
          posts && posts.slice(4).map((service) => {
            return (
              <div className={'px-20 my-8 sm:my-0 sm:px-4 sm:p-4 sm:col-span-2'}>
                <div className={'w-full rounded-xl mx-auto min-h-[360px] bg-gradient-to-r from-cta-grad-to to-cta-grad-from shadow-md flex items-center'}>
                <Link to={`/spiritual-guidance/${service.slug}`}>
                <div className={'w-full'}>
                <div className={' h-[180px]  flex items-end pb-4'}>
                <h4 className={'font-heading text-center text-3xl text-outlined'}>{service.title}</h4>
                </div>
                <div className={' h-[180px] px-8'}>
                  <p className={'font-primary italic px-0 text-center text-xl  text-outlined-white'}>{service.subtitle}</p>
                </div>
                </div>
                </Link>
                </div>
              </div>
            )
          })
        }
      </div>
      {spiritualGuidanceContent && <AngelTextColumn pages={spiritualGuidanceContent.slice(2,4)} />}
      { spiritualGuidanceContent &&
        <>
      <div className={'flex justify-center w-full mt-8'}>
        <h2 className={'font-heading font-bold mx-auto text-4xl sm:text-6xl text-center text-giek-dark-violet glowing-purple-text mt-16 mb-6'}>{spiritualGuidanceContent[4].title}</h2>
      </div>
      <div className={'relative max-w-screen-lg mx-auto my-32 p-8 border-t border-b border-giek-grey'}>
      <div className={'absolute left-0 -top-16 flex w-full justify-center'}>
        <PinwheelSvg />
      </div>
        <div className={'border-giek-gray border-b border-b-giek-gray sm:p-8'}>
            <p className={'font-secondary text-4xl italic '} dangerouslySetInnerHTML={{__html: spiritualGuidanceContent[4].para_one}}></p>
        </div>
        <div className={'sm:p-8 py-8'}>
          <p className={'font-primary text-xl'} dangerouslySetInnerHTML={{__html: spiritualGuidanceContent[4].para_two}}></p>
        </div>
      </div>
      </>
}
    {spiritualGuidanceContent &&

      <div className={'max-w-screen-lg mx-auto my-32 p-8 sm:flex rounded-xl'}>
      <div className={'border-giek-gray border-b border-t border-giek-gray py-8 sm:p-8'}>
      <p className={'font-secondary text-xl italic h-full items-center flex'} dangerouslySetInnerHTML={{__html: spiritualGuidanceContent[5].para_one}}></p>
      </div>
      <div className={'py-8 sm:p-8'}>
        <p dangerouslySetInnerHTML={{__html: spiritualGuidanceContent[5].embed}}></p>
      </div>
      </div>
    }




    </div>
  )
}

export default SpiritualGuidance;
