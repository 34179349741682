import React, {useState, useEffect} from 'react';
import './Slider.css';
import {useSwipeable} from 'react-swipeable'
import Text from '../text/Text';
import Image from '../image/Image';
import Slide from './slide/Slide';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Slider = ({heading, testimonials}) => {
  const [active, setActive] = useState(0)

  const config = {
  delta: 10,                             // min distance(px) before a swipe starts. *See Notes*
  preventScrollOnSwipe: false,           // prevents scroll during swipe (*See Details*)
  trackTouch: true,                      // track touch input
  trackMouse: false,                     // track mouse input
  rotationAngle: 0,                      // set a rotation angle
  swipeDuration: Infinity,               // allowable duration of a swipe (ms). *See Notes*
  touchEventOptions: { passive: true },  // options for touch listeners (*See Details*)
}

  const handlers = useSwipeable({
    onSwipedLeft: (eventData) => setActive(active < testimonials.length - 1 ? active + 1 : 0),
    onSwipedRight: (eventData) => setActive(active > 0 ? active - 1 : testimonials.length - 1),
    ...config
  })

  return (
    <React.Fragment>
    <div className='slider-wrapper' {...handlers}>
    <h2 className={'ml-6 text-giek-dark-violet glowing-purple-text font-heading text-3xl sm:text-4xl mb-16 sm:mb-8'}>{heading}</h2>
    {/*props.paragraph && <Text type={'paragraph'} color={'#1b2b4e'} text={props.paragraph} />*/}
      <div className='slider'>
        {
          testimonials && testimonials.map((slide, idx) => {
            return (
                <Slide active={active} text={slide.testimonial} key={idx} idx={idx} detail={slide.customer} fontSize={slide.fontSize}/>
            )
          })
        }
        <div className='slider-controls scale-[0.875] sm:scale-1 translate-y-[8px] sm:translate-y-[38px]'>
          <button onClick={() => setActive(active > 0 ? active - 1 : testimonials.length - 1)}>
          <svg className={'fill-giek-dark-violet'} viewBox="0 0 20 20"><path d="M11.739,13.962c-0.087,0.086-0.199,0.131-0.312,0.131c-0.112,0-0.226-0.045-0.312-0.131l-3.738-3.736c-0.173-0.173-0.173-0.454,0-0.626l3.559-3.562c0.173-0.175,0.454-0.173,0.626,0c0.173,0.172,0.173,0.451,0,0.624l-3.248,3.25l3.425,3.426C11.911,13.511,11.911,13.789,11.739,13.962 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.148,3.374,7.521,7.521,7.521C14.147,17.521,17.521,14.148,17.521,10"></path></svg>
          </button>
          <button onClick={() => setActive(active < testimonials.length - 1 ? active + 1 : 0)}>
          <svg className={'fill-giek-dark-violet'} viewBox="0 0 20 20">
  							<path d="M12.522,10.4l-3.559,3.562c-0.172,0.173-0.451,0.176-0.625,0c-0.173-0.173-0.173-0.451,0-0.624l3.248-3.25L8.161,6.662c-0.173-0.173-0.173-0.452,0-0.624c0.172-0.175,0.451-0.175,0.624,0l3.738,3.736C12.695,9.947,12.695,10.228,12.522,10.4 M18.406,10c0,4.644-3.764,8.406-8.406,8.406c-4.644,0-8.406-3.763-8.406-8.406S5.356,1.594,10,1.594C14.643,1.594,18.406,5.356,18.406,10M17.521,10c0-4.148-3.374-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.147,17.521,17.521,14.147,17.521,10"></path></svg>
          </button>
        </div>
        <div className='swiper-indicators'>
          <div className='left-swiper-indicator'>
            <FontAwesomeIcon icon="fas fa-hand-point-right" />
          </div>
          <div className='right-swiper-indicator'>
            <FontAwesomeIcon icon="fas fa-hand-point-right" />
          </div>
        </div>
      </div>
     </div>
    </React.Fragment>
  )
}

export default Slider;
