import React from 'react';
import './Slide.css';
import Text from '../../text/Text';
import Image from '../../image/Image';


export const Slide = (props) => {
  return (
    <React.Fragment>
    <div className={`slide bg-gradient-to-r from-cta-grad-to to-cta-grad-from rounded-lg ${props.active == props.idx ? 'active-slide' : ''} ${props.active > props.idx ? 'prev-slide' : ''} ${props.active < props.idx ? 'next-slide' : ''}`}>
      {/*<Image src={evening} width={'240px'} radius={"100%"} />*/}
      <div className={'flx-column flx-vcenter'}>
        <p className={'italic font-primary text-giek-dark-violet text-md'} dangerouslySetInnerHTML={{__html: props.text}}></p>
        <p className={'font-primary text-giek-dark-violet font-bold mt-2'}>{props.detail}</p>
      </div>
    </div>
    </React.Fragment>
  )
}

export default Slide;
